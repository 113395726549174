import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import TableDiv from '@components/table/table-div'
import CodeBulksListEntry from '@pages/codes/code-bulk/code-bulks-list-entry/code-bulks-list-entry'
import useGetCodeBulks from '@services/api/use-get-code-bulks'
import { captureException } from '@services/exceptions/capture-exception'

const CodeBulksList = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    ...Object.assign({
      'order[createdAt]': 'DESC'
    })
  })
  const [itemsPerPage, setItemsPerPage] = useState(
    parseInt(searchParams.get('itemsPerPage') ?? '20') ?? 20
  )
  const pageIndex = Number(searchParams.get('page') ?? '1')

  const {
    data: {
      'hydra:member': codeBulks = [],
      'hydra:totalItems': totalItems = 0,
      'hydra:view': pagination = undefined
    } = {},
    isLoading,
    refetch
  } = useGetCodeBulks({
    parameters: searchParams
  })

  useEffect(() => {
    refetch().catch(captureException)
  }, [searchParams])

  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  return (
    <Layout wide>
      <Guard errorPage={false}>
        <>
          <div className='mx-auto max-w-4xl px-2 pb-20 sm:px-6 lg:px-8'>
            <div className='mt-10 flex flex-col items-center'>
              <div className='text-center'>
                <h2 className='text-2xl font-bold'>Your codes</h2>

                <p className='my-2'>List of all your audioguide codes</p>
              </div>
            </div>

            <TableDiv
              bodyClasses='space-y-2'
              className='mt-4'
              definitionName='code-bulks'
              headers={[]}
              isLoading={isLoading}
              itemsPerPage={itemsPerPage}
              pageIndex={pageIndex}
              pagination={pagination}
              setItemsPerPage={setItemsPerPage}
              setPageIndex={setPageIndex}
              totalItems={totalItems}
            >
              {codeBulks?.map((codeBulk) => <CodeBulksListEntry codeBulk={codeBulk} key={codeBulk.uid} />)}
            </TableDiv>
          </div>
        </>
      </Guard>
    </Layout>
  )
}

export default CodeBulksList
