import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Table from '@components/table/table'
import type { TableHeader } from '@components/table/table.interfaces'
import { type CodeGroup } from '@interfaces/api/code-group'
import { CodeBulkGroupModal } from '@pages/codes/code-bulk-details/code-bulk-group-modal'
import { CodeBulkGroupViewModal } from '@pages/codes/code-bulk-details/code-bulk-group-view-modal'
import { type CodeBulkGroupsInterfaces } from '@pages/codes/code-bulk-details/code-bulk-groups'
import { CodeGroupListEntry } from '@pages/codes/code-bulk-details/code-bulk-groups/code-group-list-entry'
import useGetCodeBulksGroup from '@services/api/use-get-code-bulk-groups'
import useGetCodeBulksCodes from '@services/api/use-get-code-bulks-codes'
import { captureException } from '@services/exceptions/capture-exception'

const CodeBulkGroups = ({ codeBulk }: CodeBulkGroupsInterfaces) => {
  const [searchParams, setSearchParams] = useSearchParams({
    'order[createdAt]': 'DESC'
  })
  const [itemsPerPage, setItemsPerPage] = useState(parseInt(searchParams.get('itemsPerPage') ?? '20') ?? 20)
  const pageIndex = Number(searchParams.get('page') ?? '1')
  const [openGroupForm, setOpenGroupForm] = useState(false)
  const [currentCodeGroup, setCurrentCodeGroup] = useState<CodeGroup>()
  const [openDetails, setOpenDetails] = useState(false)
  const [filter, setFilter] = useState('')
  const [orderNumberFilter, setOrderNumberFilter] = useState('')

  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const headers: TableHeader[] = [
    { name: 'orderNumber' },
    { name: 'tickets' },
    { name: 'createdAt', sort: true, sortDefault: 'DESC' },
    { name: 'actions' }
  ]

  const {
    data: { 'hydra:member': codeList = [] } = {}
  } = useGetCodeBulksCodes({ uid: codeBulk.uid ?? '' })

  const {
    data: { 'hydra:member': codeGroups = [], 'hydra:totalItems': totalItems = 0, 'hydra:view': pagination = undefined } = {},
    isLoading,
    refetch
  } = useGetCodeBulksGroup({ parameters: searchParams, uid: codeBulk.uid ?? '' })

  useEffect(() => {
    refetch().catch(captureException)
  }, [searchParams])

  const handleCodeEditClick = (codeGroup: CodeGroup) => {
    setCurrentCodeGroup(codeGroup)
    setOpenGroupForm(true)
  }

  const handleCodeEntryClick = (codeGroup: CodeGroup) => {
    setCurrentCodeGroup(codeGroup)
    setOpenDetails(true)
  }

  const onModalSuccess = () => {
    refetch().catch(captureException)
  }

  const filteredCodeGroups = codeGroups.filter((group) => {
    const commentMatch = (group.comment?.toLowerCase() ?? '').includes(filter.toLowerCase())
    const orderNumberMatch = group.orderNumber
      ? group.orderNumber.toString().includes(orderNumberFilter)
      : false

    return commentMatch && orderNumberMatch
  })

  return (
    <div className='mt-4'>
      <div className='flex flex-col md:flex-row md:space-x-4'>
        <input
          className='mt-2 block rounded-lg border px-3 py-2.5 text-sm'
          onChange={(e) => {
            setOrderNumberFilter(e.target.value)
          }}
          placeholder='Filter by order number'
          type='text'
          value={orderNumberFilter}
        />

        <input
          className='mt-2 block rounded-lg border px-3 py-2.5 text-sm'
          onChange={(e) => {
            setFilter(e.target.value)
          }}
          placeholder='Filter by label'
          type='text'
          value={filter}
        />
      </div>

      {filteredCodeGroups && (
        <Table
          containerClassname='mt-2'
          definitionName='code-groups'
          headers={headers}
          isLoading={isLoading}
          itemsPerPage={itemsPerPage}
          pageIndex={pageIndex}
          pagination={pagination}
          setItemsPerPage={setItemsPerPage}
          setPageIndex={setPageIndex}
          totalItems={totalItems}
        >
          {filteredCodeGroups?.map((codeGroup) => (
            <CodeGroupListEntry
              codeBulk={codeBulk}
              codeGroup={codeGroup}
              key={codeGroup.uid}
              onEditClick={handleCodeEditClick}
              onEntryClick={handleCodeEntryClick}
            />
          ))}
        </Table>
      )}

      {codeBulk && currentCodeGroup && codeList && (
        <CodeBulkGroupModal
          codeBulk={codeBulk}
          codeGroup={currentCodeGroup}
          codeList={codeList}
          onSuccess={onModalSuccess}
          open={openGroupForm}
          setOpen={setOpenGroupForm}
        />
      )}

      {codeBulk && currentCodeGroup && (
        <CodeBulkGroupViewModal
          codeGroup={currentCodeGroup}
          open={openDetails}
          setOpen={setOpenDetails}
        />
      )}
    </div>
  )
}

export default CodeBulkGroups
