import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import slugify from 'slugify'

import Button from '@components/button/button'
import Table from '@components/table/table'
import type { TableHeader } from '@components/table/table.interfaces'
import type { Code } from '@interfaces/api/code'
import { type CodeGroup } from '@interfaces/api/code-group'
import { type CodeBulkCodesInterfaces } from '@pages/codes/code-bulk-details/code-bulk-codes'
import CodeListEntry from '@pages/codes/code-bulk-details/code-bulk-codes/code-list-entry/code-list-entry'
import { CodeBulkGroupModal } from '@pages/codes/code-bulk-details/code-bulk-group-modal'
import { CodeBulkGroupViewModal } from '@pages/codes/code-bulk-details/code-bulk-group-view-modal'
import useGetCodeBulkCsv from '@services/api/use-get-code-bulk-csv'
import useGetCodeBulksCodes from '@services/api/use-get-code-bulks-codes'
import { captureException } from '@services/exceptions/capture-exception'

const CodeBulkCodes = ({ codeBulk }: CodeBulkCodesInterfaces) => {
  const headers: TableHeader[] = [
    { name: 'code' },
    { name: 'usedAt', sort: true, sortDefault: 'DESC' },
    { name: 'sentAt', sort: true, sortDefault: 'DESC' },
    { name: 'actions' }
  ]

  const [searchParams, setSearchParams] = useSearchParams({})
  const [itemsPerPage, setItemsPerPage] = useState(parseInt(searchParams.get('itemsPerPage') ?? '20') ?? 20)
  const pageIndex = Number(searchParams.get('page') ?? '1')
  const [shouldDownload, setShouldDownload] = useState(false)
  const [localCodes, setLocalCodes] = useState<Code[]>([])
  const [isSelecting, setIsSelecting] = useState(false)
  const [selectedCodes, setSelectedCodes] = useState<Set<string>>(new Set())
  const [openGroupForm, setOpenGroupForm] = useState(false)
  const [createdGroup, setCreatedGroup] = useState<CodeGroup>()
  const [openViewGroup, setOpenViewGroup] = useState(false)

  const { data: csvData, isFetching, isSuccess: isCsvSuccess } = useGetCodeBulkCsv(codeBulk.uid ?? '', shouldDownload)

  const {
    data: { 'hydra:member': codes = [], 'hydra:totalItems': totalItems = 0, 'hydra:view': pagination = undefined } = {},
    isLoading,
    refetch
  } = useGetCodeBulksCodes({ parameters: searchParams, uid: codeBulk.uid ?? '' })

  useEffect(() => {
    refetch().catch(captureException)
  }, [searchParams])

  useEffect(() => {
    if (codes.length > 0) {
      const filteredCodes = codes.filter((code) => code.groupUid === null)
      setLocalCodes(filteredCodes)
    }
  }, [codes])

  const updateLocalCode = (updatedCode: Code) => {
    setLocalCodes((prevCodes) =>
      prevCodes.map((code) => {
        if (code.code === updatedCode.code) {
          return { ...code, sentAt: updatedCode.sentAt }
        }

        return code
      })
    )
  }

  useEffect(() => {
    if (isCsvSuccess && csvData && codeBulk) {
      const totalCount = codeBulk.totalCount
      const fileName = `codes_${slugify(codeBulk.experience.name, { lower: true })}_${totalCount}.csv`

      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      setShouldDownload(false)
    }
  }, [csvData, isCsvSuccess, codeBulk])

  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const downloadCSV = () => {
    setShouldDownload(true)
  }

  const toggleSelectionMode = () => {
    setIsSelecting((prev) => !prev)
    setSelectedCodes(new Set())
  }

  const toggleSelect = (code: string) => {
    setSelectedCodes((prev) => {
      const newSelected = new Set(prev)
      if (newSelected.has(code)) {
        newSelected.delete(code)
      } else {
        newSelected.add(code)
      }

      return new Set(newSelected)
    })
  }

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedCodes(new Set(localCodes.map((code) => code.code)))
    } else {
      setSelectedCodes(new Set())
    }
  }

  const handleCreateGroup = async () => {
    setOpenGroupForm(true)
  }

  const onModalSuccess = (codeGroup?: CodeGroup) => {
    if (codeGroup) {
      setCreatedGroup(codeGroup)
      setOpenViewGroup(true)
      setSelectedCodes(new Set())
      setIsSelecting(false)
    }
    refetch().catch(captureException)
  }

  return (
    <div className=''>
      {localCodes && codeBulk && (
        <div className='mt-4'>
          <div className='flex justify-between'>
            <Button icon={ArrowDownOnSquareIcon} isLoading={isFetching} onClick={downloadCSV} size={'medium'}
              style={'black'}
            >
              Download CSV
            </Button>

            <div className='flex space-x-2'>
              {selectedCodes.size > 0 && (
                <Button
                  onClick={handleCreateGroup}
                  size={'medium'}
                >
                  {`Create group (${selectedCodes.size})`}
                </Button>
              )}

              <Button
                onClick={toggleSelectionMode}
                size={'medium'}
                style={'black'}
              >
                {isSelecting ? 'Cancel selection' : 'Select multiple'}
              </Button>
            </div>

          </div>

          <Table
            containerClassname='mt-4'
            definitionName='codes'
            headers={headers}
            isLoading={isLoading}
            isSelecting={isSelecting}
            itemsPerPage={itemsPerPage}
            onSelectAll={handleSelectAll}
            pageIndex={pageIndex}
            pagination={pagination}
            setItemsPerPage={setItemsPerPage}
            setPageIndex={setPageIndex}
            totalItems={totalItems}
          >
            {localCodes
              ?.filter((code) => !isSelecting || (!code.sentAt && !code.usedAt)) // Appliquer le filtre uniquement si isSelecting est true
              .map((code) => (
                <CodeListEntry
                  code={code}
                  codeBulk={codeBulk}
                  isSelected={selectedCodes.has(code.code)}
                  isSelecting={isSelecting}
                  key={code.uid}
                  toggleSelect={toggleSelect}
                  updateLocalCode={updateLocalCode}
                />
              ))}
          </Table>
        </div>
      )}

      {codeBulk && (
        <CodeBulkGroupModal
          codeBulk={codeBulk}
          codeList={localCodes}
          codes={Array.from(selectedCodes.values())}
          onSuccess={onModalSuccess}
          open={openGroupForm}
          setOpen={setOpenGroupForm}
        />
      )}

      {codeBulk && createdGroup && (
        <CodeBulkGroupViewModal codeGroup={createdGroup} open={openViewGroup} setOpen={setOpenViewGroup} />
      )}
    </div>
  )
}

export default CodeBulkCodes
