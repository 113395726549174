import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useParams } from 'react-router'

import Button from '@components/button/button'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { CodeBulkCodes } from '@pages/codes/code-bulk-details/code-bulk-codes'
import { type CodeBulkDetailsInterfaces } from '@pages/codes/code-bulk-details/code-bulk-details.interfaces'
import { CodeBulkGroups } from '@pages/codes/code-bulk-details/code-bulk-groups'
import { CodeBulkMenu } from '@pages/codes/code-bulk-details/code-bulk-menu'
import useGetCodeBulk from '@services/api/use-get-code-bulk'

const CodeBulkDetails = ({ groups }: CodeBulkDetailsInterfaces) => {
  const { uid } = useParams()

  const { data: codeBulk } = useGetCodeBulk(uid ?? '')

  return (
    <Layout wide>
      <Guard errorPage={false}>
        <div className='mx-auto max-w-7xl px-2 pb-20 sm:px-6 lg:px-8'>
          {codeBulk && (
            <div className='mt-10 grid grid-cols-3 items-center'>
              <div className='flex items-start'>
                <Button href='/codes' icon={ChevronLeftIcon} iconRight={false} size={'small'} />
              </div>

              <div className='text-center'>
                <h2 className='text-2xl font-bold'>
                  {codeBulk?.label && `${codeBulk.label} - `}

                  {codeBulk?.experience.name}
                </h2>

                <p className='my-2 text-xs uppercase'>{`Available codes : ${codeBulk?.remainingUnsentCount} / ${codeBulk?.totalCount}`}</p>

                {/**
                <p className='my-2 text-xs uppercase'>{`Redeemed codes : ${codeBulk?.totalCount - codeBulk.remainingCount}`}</p>
                 **/}
              </div>

              <div />
            </div>
          )}

          <div className='flex items-center justify-center'>
            <CodeBulkMenu />
          </div>

          {codeBulk && !groups && (
            <CodeBulkCodes codeBulk={codeBulk} />
          )}

          {codeBulk && groups && (
            <CodeBulkGroups codeBulk={codeBulk} />
          )}

        </div>
      </Guard>
    </Layout>
  )
}

export default CodeBulkDetails
