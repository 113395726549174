import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'

const CodeBulkMenu = () => {
  const defaultLinkStyle = 'inline-flex items-center rounded-lg border px-5 py-2 text-sm font-semibold text-black'
  const { uid } = useParams()

  return (
    <div className='my-4 flex space-x-2'>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? `${defaultLinkStyle} border-black bg-black text-white`
            : `${defaultLinkStyle} border-gray-200 bg-white text-black`
        }
        end
        to={`/codes/${uid}`}
      >
        Codes
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive
            ? `${defaultLinkStyle} border-black bg-black text-white`
            : `${defaultLinkStyle} border-gray-200 bg-white text-black`
        }
        to={`/codes/${uid}/groups`}
      >
        Groups
      </NavLink>
    </div>
  )
}

export default CodeBulkMenu
