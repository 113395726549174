import {
  BookingCreate, Bookings,
  Checkout, CodeBulkDetails, CodesList,
  ForgotPassword,
  Login,
  OnboardingLegal, OnboardingLogo,
  Purchase,
  Register, ResetPassword,
  Success
} from '@pages'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useLocation } from 'react-router'
import { Navigate, Route, Routes } from 'react-router-dom'

// eslint-disable-next-line no-restricted-imports
import packageInfo from '../package.json'
import EmailVerified from '@pages/email-verified'
import Home from '@pages/home/home'
import { SettingsHome, SettingsLogo, SettingsSlideshow } from '@pages/settings'
import { useAuthToken } from '@services/hooks/auth-token'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllText: false
      })
    ],
    release: `citycrush-pro@${packageInfo.version}`,

    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,

    // Session Replay
    replaysSessionSampleRate: 0.1,

    //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api.citycrushtravel\.com/,
      /^https:\/\/api-admin.staging.citycrushtravel\.com/
    ],

    // Performance Monitoring
    tracesSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const DEFAULT_STALE_TIME = 5 * 60 * 1000

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: DEFAULT_STALE_TIME
    }
  }
})

const Protected = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const { value: authToken } = useAuthToken()

  if (!authToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate replace state={{ from: location }} to='/login' />
  }

  return children
}

function App () {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Login />} path='/login' />

          <Route element={<Register />} path='/register' />

          <Route element={<EmailVerified />} path='/email-verified' />

          <Route element={<ForgotPassword />} path='/forgot-password' />

          <Route element={<ResetPassword />} path='/reset-password' />

          <Route element={<Protected><Home /></Protected>} path='/' />

          <Route element={<Protected><Purchase /></Protected>} path='/purchase' />

          <Route element={<Protected><CodesList /></Protected>} path='/codes' />

          <Route element={<Protected><CodeBulkDetails /></Protected>} path='/codes/:uid' />

          <Route element={<Protected><CodeBulkDetails groups /></Protected>} path='/codes/:uid/groups' />

          <Route element={<Protected><Bookings /></Protected>} path='/bookings' />

          <Route element={<Protected><BookingCreate /></Protected>} path='/booking/new' />

          <Route element={<Protected><Checkout /></Protected>} path='/purchase/checkout' />

          <Route element={<Protected><Success /></Protected>} path='/purchase/success' />

          <Route element={<Protected><OnboardingLegal /></Protected>} path='/onboarding' />

          <Route element={<Protected><OnboardingLogo /></Protected>} path='/onboarding/logo' />

          <Route element={<Protected><SettingsHome /></Protected>} path='/settings' />

          <Route element={<Protected><SettingsHome /></Protected>} path='/settings/company' />

          <Route element={<Protected><SettingsSlideshow /></Protected>} path='/settings/audioguide' />

          <Route element={<Protected><SettingsLogo /></Protected>} path='/settings/logo' />
        </Routes>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
