import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import moment from 'moment/moment'
import { useState } from 'react'

import ButtonTooltip from '@components/button-tooltip/button-tooltip'
import ModalDeleteConfirm from '@components/modal-delete-confirm/modal-delete-confirm'
import { type Code } from '@interfaces/api/code'
import { type CodeBulk } from '@interfaces/api/code-bulk'
import usePatchCodeBulk from '@services/api/use-patch-code-bulk'
import { captureException } from '@services/exceptions/capture-exception'

interface CodeListEntryProps {
  code: Code
  codeBulk: CodeBulk
  isSelected: boolean
  isSelecting: boolean
  key: string
  toggleSelect: (code: string) => void
  updateLocalCode: (updatedCode: Code) => void
}
const CodeListEntry = ({ code, codeBulk, isSelected, isSelecting, key, toggleSelect, updateLocalCode }: CodeListEntryProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const { mutateAsync: patchCodeBulk } = usePatchCodeBulk()
  const onCopyClick = async (code: string) => {
    await navigator.clipboard.writeText(code)
  }

  const setCodeSent = async (e: React.MouseEvent) => {
    e.stopPropagation()
    try {
      await patchCodeBulk({
        data: {
          codes: [code.code],
          sentAt: new Date().toISOString()
        },
        uid: codeBulk.uid
      })
      updateLocalCode({ ...code, sentAt: new Date() })
    } catch (error) {
      captureException(error as Error)
    }
  }

  const setCodeUnsent = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpenDeleteModal(true)
  }

  const handleDeleteConfirm = async () => {
    try {
      await patchCodeBulk({
        data: {
          codes: [code.code],
          sentAt: null
        },
        uid: codeBulk.uid
      })
      updateLocalCode({ ...code, sentAt: '' })
      setOpenDeleteModal(false)
    } catch (error) {
      captureException(error as Error)
    }
  }

  const handleRowClick = async (e: React.MouseEvent) => {
    if (isSelecting) {
      if (!code.usedAt && !code.sentAt) {
        toggleSelect(code.code)
      }
    } else {
      await onCopyClick(code.code)
    }
  }

  return (
    <tr
      className={classNames(
        'group cursor-pointer text-sm',
        {
          'bg-gray-30 hover:bg-gray-30': isSelecting && (code.sentAt || code.usedAt) && !isSelected,
          'bg-primary/20 hover:bg-primary/20': isSelecting && isSelected,
          'bg-white hover:bg-gray-30': !isSelecting
        }
      )}
      key={code.code}
      onClick={handleRowClick}
    >
      {isSelecting && (
        <td className='flex items-center justify-center p-3'>
          {!code.sentAt && !code.usedAt && (
            <input
              checked={isSelected}
              className='size-4 rounded border-gray-300 text-indigo-600'
              onChange={() => {
                toggleSelect(code.code)
              }}
              onClick={(e) => {
                e.stopPropagation()
              }}
              type='checkbox'
            />
          )}
        </td>
      )}

      <td className='px-3 py-2 font-medium'>{code.code}</td>

      <td className='px-3 py-2'>{code.usedAt ? moment(code.usedAt).format('DD.MM.YYYY HH:mm') : '-'}</td>

      <td className='px-3 py-2'>
        {code.sentAt ? moment(code.sentAt).format('DD.MM.YYYY HH:mm') : '-'}
      </td>

      <td className='px-3 py-2'>
        <div className='flex items-center space-x-1'>
          {code.sentAt
            ? (
              <button
                className='rounded-md border border-gray-200 p-1 text-black hover:bg-black hover:text-white'
                onClick={setCodeUnsent}
                type='button'
              >
                <XMarkIcon className='size-4' />
              </button>
            )
            : (
              <button
                className='rounded-md border border-gray-200 p-1 text-black hover:bg-black hover:text-white'
                onClick={setCodeSent}
                type='button'
              >
                <CheckIcon className='size-4' />
              </button>
            )}

          <ButtonTooltip handleCopyClick={async (e) => {
            e.stopPropagation()
            await onCopyClick(code.code).catch(captureException)
          }} withBorder
          />
        </div>
      </td>

      <ModalDeleteConfirm onConfirm={handleDeleteConfirm} open={openDeleteModal} setOpen={setOpenDeleteModal} text={'Are you sure to remove the sent date ?'} title={'Confirm remove sent date'} />
    </tr>
  )
}

export default CodeListEntry
